import React from 'react'

import styled from "styled-components"

import * as Text from "/src/components/text"
import { Colors } from "/src/components/palette"

const PricesContainer = styled.div`
    display: flex;
    margin: 0 auto;
    justify-content: center;
    @media(max-width: 758px) {
        flex-direction: column;
    }
`
const PriceItemContainer = styled.div`
    flex: 1 1 calc(33% - 36px);
    margin: 12px;
    text-align: center;
    background: ${Colors.gray};
    padding: 32px 16px;
`
const PriceItem = ({ title, subtitle }) => (
    <PriceItemContainer>
        <Text.H3 center>{title}</Text.H3>
        <Text.S light small>{subtitle}</Text.S>
    </PriceItemContainer>
)
export const Prices = ({ priceItems = [] }) => (
    <PricesContainer>
        {priceItems.map(([title, subtitle]) => <PriceItem {...{ title, subtitle }} />)}
    </PricesContainer>
)