import React from 'react'
import styled from "styled-components"
import { Chevron } from "/src/components/svgs"

const FaqContainer = styled.div`
    border: 1px solid #000;
    border-radius: 46px;
    font-family: Gilroy;
`

const Question = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    cursor: pointer;
    font-size: 20px;
`

const Answer = styled.div`
    margin: 0 32px ${({isOpen}) => isOpen ? "32px": "0px"} 32px;
    transition: all 0.5s ease;
    max-height: ${({isOpen}) => isOpen ? "20rem": "0px"};
    overflow: hidden;
`


export const Faq = ({ q, a }) => {

    const [isOpen, setIsOpen] = React.useState(false)


    return (
        <FaqContainer>
            <Question
                onClick={() => setIsOpen(!isOpen)}
            >
                <div>{q}</div>
                <Chevron direction={isOpen ? "up" : "down"} style={{
                    flexShrink: 0,
                    marginLeft: "16px"
                }} />
            </Question>
            <Answer isOpen={isOpen}>{a}</Answer>
        </FaqContainer>
    )
}