import React from 'react'
import { Milestone } from "/src/components/svgs"

import styled from "styled-components"

import * as Text from "/src/components/text"

const Line = styled.div`
border-top: 1px solid rgba(0,0,0, .1);
width: 1000%;
margin-left: -100%;
@media(max-width: 768px) {
    width:0;
    margin-left: 0;
    border-top: 0;
    border-left: 1px solid rgba(0,0,0, .1);
}
`

const TimelineItemS = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
@media(max-width: 768px) {
    flex-direction: row;
    gap: 20px;
}
`

const TimelineItem = ({ title, description, i }) => (
    <TimelineItemS>
        <Milestone />
        <div style={{padding: "0 16px"}}>
            <Text.H3>{title}</Text.H3>
            <Text.S small light tight>{description}</Text.S>
        </div>
    </TimelineItemS>
)
const TimelineItemsContainer = styled.div`
display: flex;
justify-content: space-between;
margin: -9px 0 0 0;
@media(max-width: 768px) {
    flex-direction: column;
    margin: 0 0 0 -8px;
    gap: 60px;
}
`

const TimelineContainer = styled.div`
display: flex;
flex-direction: column;
margin: 60px 24px;
@media(max-width: 768px) {
    flex-direction: row;
}
`



export const Timeline = ({timelineItems=[]}) => {

    return (
        <TimelineContainer>
            <Line/>
            <TimelineItemsContainer>
                {timelineItems.map(([title, description], i) => <TimelineItem {...{description, title, i}} />)}
            </TimelineItemsContainer>
        </TimelineContainer>
    )
}