import React, { useEffect, useState } from 'react'
import { Link, } from 'gatsby'
import { Button } from "/src/components/button"
import Header, { LoginModal, Logo } from "/src/components/header"

import { gql, useQuery } from '@apollo/client'
import _ from "lodash"
import {
    CheckCircle,
    CrossCircle,
} from "/src/components/svgs"
import { Faq } from "/src/components/faq"

import { Timeline } from "/src/components/timeline"
import { Prices } from "/src/components/prices"
import { useWindowSize } from "/src/hooks"


import Seo from "../components/seo"

import { navigate } from "gatsby"

import { trackEvent } from "/src/utils"


import chairbg from "/src/images/products/chairbg.png"
import vasebg from "/src/images/products/vasebg.png"
import horsebg from "/src/images/products/horsebg.png"
import ukraine from "/src/images/products/ukraine.png"


// partners
import usaid from "/src/images/logos/usaid.png"
import ukrposhta from "/src/images/logos/ukrposhta.png"
import techbureau from "/src/images/logos/techbureau.png"

import techbureau_oneline from "/src/images/logos/techbureau_oneline.png"

import dia from "/src/images/logos/dia.png"
import eexport from "/src/images/logos/eexport.png"
import { Stack } from "/src/components/grid"

import * as Text from "/src/components/text"

import styled from "styled-components"
import { Colors } from "/src/components/palette"

const partnersImages = [
    [usaid, "https://www.facebook.com/usaidcep.ua"],
    [ukrposhta, "https://www.ukrposhta.ua/ua"],
    [techbureau, "https://www.facebook.com/NGO.TECHBUREAU"],
    [dia, "https://business.diia.gov.ua/"]
]

const productImages = [
    chairbg,
    vasebg,
    horsebg,
]
const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
    @media(max-width: 1279px) {
        max-width: 1024px;
    }
`


const Partners = () => {
    const { width } = useWindowSize();



    return (
        <div
            style={{
                background: Colors.gray,
                marginTop: 27
            }}>
            <Container>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection: width < 768 ? "column" : "row",
                        alignItems: "center"
                    }}
                >   {partnersImages.map(([img, url]) => {
                    return (<a style={{ padding: 30, display: "block" }} href={url} target="_blank" rel="noreferrer">
                        <img src={img} style={{ maxHeight: 50, maxWidth: 150, margin: 0 }} />
                    </a>
                    )
                })}

                </div>
            </Container>
        </div>
    )
}


const DEADLINE = "05/11/2022"

const calculateTimeLeft = (toDate = "05/11/2022") => {
    let difference = new Date(toDate) - new Date();

    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }

    return timeLeft;
}

const pluralizeDays = (v) => {
    return pluralize(v, "день", "дні", "днів")
}

const pluralizeHours = (v) => {
    return pluralize(v, "година", "години", "годин")
}

const pluralizeMinutes = (v) => {
    return pluralize(v, "хвилина", "хвилини", "хвилин")
}

const pluralize = (v, f1, f2, f3) => {
    const r = v % 10
    if (r === 1 && (v > 20 || v === 1)) return ` ${v} ${f1}`
    if (r >= 2 && r <= 4 && (v > 20 || v < 10)) return `${v} ${f2}`
    return `${v} ${f3}`

}

const Countdown = ({ toDate = "05/11/2022" }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(toDate))
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(toDate));
        }, 60 * 1000);

        return () => clearInterval(timer);
    }, [toDate])
    return (
        <div>{pluralizeDays(timeLeft.days)} : {pluralizeHours(timeLeft.hours)} : {pluralizeMinutes(timeLeft.minutes)}</div>
    )
}


const SectionS = styled.div`
overflow: hidden;
margin-top: 144px;
@media(max-width: 768px) {
    margin-top: 72px;
}


`

const Section = ({ title, children, ...rest }) => {
    return (
        <SectionS {...rest}>
            <Container>
                <div style={{ maxWidth: 410, margin: "0 auto" }}>

                    <Text.H2 center>{title}</Text.H2>
                </div>
                {children}
            </Container>
        </SectionS>
    )
}

const HeroImage = styled.img`
    margin: 0;
    flex-shrink: 1;
    width: 100%;
    height: auto;
    min-width: 400px;
    max-width: 600px;
    align-self: flex-end;
    @media(max-width: 768px) {
        align-self: auto;
        min-width: 300px;
        max-width: 500px;
    }
`

const Hero = ({ onCta }) => {
    const { width } = useWindowSize();

    const [ix, setIx] = React.useState(0)
    React.useEffect(() => {
        const id = setInterval(
            () => setIx(ix < productImages.length - 1
                ? ix + 1
                : 0
            ),
            5000
        )
        return () => clearInterval(id);
    }, [ix])
    return (
        <div>
            <Container>

                <div
                    style={{
                        display: "flex",
                        flexDirection: width < 768 ? "column" : "row",
                        position: "relative",
                        zIndex: 2,
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <div style={{ maxWidth: 550 }}>
                        <h1
                            style={{
                                fontFamily: "TT Trailers",
                                fontSize: width < 768 ? 36 : 56,
                                textAlign: width < 768 ? "center" : "left",
                            }}
                        >$100 000 для 100 українських крафтових виробників</h1>
                        <Text.S light style={{
                            textAlign: width < 768 ? "center" : "left",
                            display: "block"
                        }}>Проєкт допомоги малим українським виробництвам під час війни Росії проти України</Text.S>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: width < 768 ? "column" : "row",
                            }}
                        >
                            <div style={{
                                border: "1px solid rgba(0,0,0,0.05)",
                                padding: "8px",
                                borderRadius: "50px",
                                margin: "16px -16px"
                            }}>

                                <div style={{
                                    border: "1px solid rgba(0,0,0,0.2)",
                                    padding: "8px",
                                    borderRadius: "50px",
                                    cursor: "pointer"
                                }}
                                onClick={() => navigate("/results/")}
                                >
                                    <div style={{
                                        background: "#000",
                                        color: "#fff",
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "8px",
                                        borderRadius: "50px"
                                    }}>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="16" fill="#F3CF21" />
                                            <path d="M20.2675 15.5551C22.161 15.3499 23.5527 12.3613 23.5527 8.46008C23.5527 8.16345 23.3245 7.9354 23.028 7.9354H20.5642V7.52483C20.5642 7.22821 20.336 7.00016 20.0395 7.00016L11.9634 7C11.6668 7 11.4388 7.2282 11.4388 7.52468V7.93524L8.97487 7.9354C8.67824 7.9354 8.4502 8.1636 8.4502 8.46008C8.4502 12.3611 9.84183 15.3498 11.7354 15.5551C12.2373 17.0837 13.3553 18.27 14.7469 18.7035V18.7947C14.7469 20.4145 13.1727 21.7376 11.2337 21.7376C10.937 21.7376 10.709 21.9658 10.709 22.2622V24.4752C10.709 24.7718 10.9372 24.9998 11.2337 24.9998L16.0017 25H20.747C21.0436 25 21.2717 24.7718 21.2717 24.4753V22.2624C21.2717 21.9658 21.0435 21.7377 20.747 21.7377C18.8078 21.7377 17.2338 20.4145 17.2338 18.7948V18.7036C18.6252 18.27 19.7428 17.0836 20.2675 15.5551ZM22.5034 8.98479C22.412 11.8137 21.4766 13.8897 20.5185 14.4145C20.5412 14.1863 20.5641 13.9582 20.5641 13.7073V13.6161V13.5249V8.96216L22.5034 8.962L22.5034 8.98479ZM9.49955 8.98479H11.4388V13.5475V13.6387V13.7299C11.4388 13.9581 11.4616 14.2091 11.4845 14.4371C10.5263 13.8898 9.59092 11.8139 9.49955 8.98479ZM20.2448 22.7643V23.9961H11.7581V22.787C13.971 22.5588 15.7277 20.9162 15.7733 18.9315H16.0015H16.2297C16.2752 20.9162 18.0091 22.5588 20.2448 22.7643ZM16.0015 17.8821C14.0622 17.8821 12.4653 16.0113 12.4653 13.7072V13.616V13.5249V8.02675H19.5147V13.5477V13.6389V13.73C19.5377 16.0116 17.9407 17.8821 16.0015 17.8821Z" fill="black" stroke="black" stroke-width="0.2" />
                                        </svg>
                                        <div style={{
                                            margin: "0 8px 0 8px"
                                        }}>

                                            Результати
                                        </div>

                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                                            margin: "0 4px 0 0"
                                        }}>
                                            <path d="M23 16L11 26L16 16L11 6L23 16Z" fill="white" />
                                        </svg>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <HeroImage src={ukraine} />
                </div>
            </Container>
        </div>
    )
}

const faqItems = [
    [
        "Як швидко я отримаю допомогу?",
        "Гроші надійдуть на ваш рахунок протягом місяця після оголошення результатів відбору."
    ],
    [
        "На що можна витратити гроші?",
        "На будь-що, що допоможе вам зберегти бізнес під час війни."
    ],
    [
        "Чи потрібно буде розповісти, як ми витратили гроші?",
        "Ми вболіваємо за малі українські виробництва й дуже переймаємося тим, як вони переживуть війну. Будемо раді почути про ваші успіхи після одержання допомоги."
    ],
    [
        "Чи потрібно буде сплатити податок на виграш?",
        "Це залежить від форми реєстрації вашого підприємства. Щоб дізнатися, чи потрібно сплачувати податок та його точну суму, перепитайте свого бухгалтера."
    ],
    [
        "Чи можна заповнити заявку російською мовою?",
        "Ні. Заявку потрібно заповнити українською мовою, інакше ми не зможемо її розглянути."
    ],
    [
        "Скільки заявок я можу подати?",
        "Одне виробництво — одна заявка. Одна юридична особа також може подати лише одну заявку."
    ],
    [
        "Як ви повідомите результати відбору?",
        "Ви отримаєте листа із результатами конкурсу 31 травня. Не забудьте перевірити пошту в цей день."
    ],
]


const InfoItemContainer = styled.div`
    background: ${Colors.gray};
    padding: 24px;
    display: flex;
`

const InfoItem = ({ title, description, positive = true, ...rest }) => {

    return (
        <InfoItemContainer {...rest}>
            <div style={{
                flexShrink: 0
            }}>
                {positive ? <CheckCircle
                    checkColor="black"
                    circleColor="#DBB98B"
                /> : <CrossCircle />}
            </div>
            <div style={{ marginLeft: "16px" }}>
                <Text.Bold>{title}</Text.Bold>
                <div><Text.S light small tight>{description}</Text.S></div>
            </div>
        </InfoItemContainer>
    )
}

const lookingForItems = [
    ["Виробників", true, "Лампи, свічки, теплі светри — ви створюєте щось, що можна потримати в руках."],
    ["Українських", true, "Ваше виробництво знаходиться в Україні й створює робочі місця в місті або селі."],
    ["Офіційних", true, "У вас є ТОВ або ФОП другої чи третьої групи."],
]


const timelineItems = [
    ["Заповніть заявку", "Дедлайн — 10 травня. Розкажіть про своє виробництво та процеси під час війни: це займе не більш як пів години. "],
    ["Дочекайтеся результатів ", "Результати будуть відомі вже 31 травня. Переможців оберуть партнери проєкту внутрішнім голосуванням. "],
    ["Отримайте допомогу ", "Дотримуйте інструкцій, щоб отримати допомогу на свій рахунок. Ми надішлемо правила окремим листом."],
]


const priceItems = [
    ["$1 000 на розвиток", "100 виробників одержать гроші на евакуацію команди до іншого міста, розвиток виробництва та інші важливі інвестиції у часи війни від USAID КЕУ."],
    ["Промокоди на безкоштовну доставку", "11 виробників одержать промокоди на безкоштовну міжнародну доставку номіналом від 5 000 до 30 000 гривень від Укрпошти."],
    ["Індивідуальні консультації", "10 виробників одержать персоналізовані поради з трендів e-commerce у їхній ніші від Дія.Бізнес."],
]

const OrgsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

`

const OrgCardContainer = styled.div`

    display: flex;
    flex-direction: column;
    align-items: left;
    margin:12px;
    padding: 24px;
    min-width: 350px;
    flex: 1 1 420px;
    @media(max-width: 650px) {
        width:100%;
        flex: 1 1 400px;
    }
`
const LogoContainer = styled.a`
    background: ${({ logo }) => `url('${logo}') center center / contain no-repeat`};
    height: 100px;
    max-width: 228px;
    width: 100%;
    display: block;
`
const OrgTitleContainer = styled.div`
    margin-top: 24px;
    padding: 24px 0;
    border-top: 1px solid rgba(0,0,0,.1);
`


const OrgCard = ({ logo, title, description, url }) => (
    <OrgCardContainer>
        <LogoContainer {...{ logo }} href={url} target="_blank" rel="noreferrer"></LogoContainer>
        <OrgTitleContainer>
            <Text.S bold>{title}</Text.S>
        </OrgTitleContainer>
        <Text.S small light tight>{description}</Text.S>

    </OrgCardContainer>

)

const Orgs = ({ orgItems = [] }) => (
    <OrgsContainer>
        {orgItems.map(([logo, title, description, url]) => <OrgCard {...{ logo, title, description, url }} />)}
    </OrgsContainer>

)

const InfoSupportCardContainer = styled.div`
display: flex;
align-items: center;
flex-direction: column;
padding: 20px 0 0 0;
flex: 1 1 300px;
`
const InfoSupportCard = ({ logo, description, url }) => (
    <InfoSupportCardContainer>
        <InfoSupportCardLogo {...{ logo }} href={url} target="_blank" rel="noreferrer" />
        <div style={{
            textAlign: "center",
            padding: "60px 10px 60px 0",
            height: 200
        }}>
            <Text.S light center >
                {description}
            </Text.S>
        </div>

    </InfoSupportCardContainer>
)
const InfoSupportCardLogo = styled.a`
    background: ${({ logo }) => `url('${logo}') center center / contain no-repeat`};
    height: 140px;
    max-width: 290px;
    width: 100%;
    display: block;
`

const infoSupportItems = [
    [eexport, "Навчальні матеріали та вебінари для е-експортерів, що працюють під час війни. Інформація для тих, хто хоче вийти на міжнародні маркетплейси.", "https://www.ukrposhta.ua/ua/e-export-school"],
    [dia, "Державні та донорські програми, приватні ініціативи підтримки підприємців в умовах війни, що допоможуть зберегти бізнес, робочі місця та підтримати економіку України.", "https://business.diia.gov.ua/wartime"]
]

const orgItems = [
    [usaid, "Програма USAID «Конкурентоспроможна економіка України» (КЕУ)", "Підтримує стартапи й малі та середні підприємства (МСП) з метою підвищення їхньої конкурентоспроможності на внутрішньому ринку України та на міжнародних ринках, допомагає в розбудові спрощеного та прозорого бізнес-клімату, а також забезпечує українські компанії можливостями скористатись перевагами міжнародної торгівлі.", "https://www.facebook.com/usaidcep.ua"],
    [ukrposhta, "АТ «Укрпошта». Єдиний національний оператор поштового зв’язку України.", `№1 у міжнародній доставці — компанія співпрацює з операторами 191 країни світу та здійснює доставку в Україну відправлень із 230 країн і територій світу. 33 місце в рейтингу поштового розвитку Всесвітнього поштового союзу. АТ “Укрпошта” надає понад 50 видів послуг приватним і корпоративним клієнтам. Мережа налічує понад 11 000 поштових відділень та пунктів обслуговування, а також 2500 пересувних відділень.`, "https://www.ukrposhta.ua/ua"],
    [techbureau_oneline, "Громадська Організація з фокусом на підтримці малого та середнього бізнесу в Україні", "Розробляє проєкти для українських стартапів, технологічних продуктів, виробників, та інших підприємців. Допомагає залучати інвестиції, виходити на міжнародні ринки та збільшувати обсяги продажів.", "https://www.facebook.com/NGO.TECHBUREAU"],
    [dia, "Національний проєкт з розвитку підприємництва та експорту", "Проєкт ініційовано Міністерством цифрової трансформації України у лютому 2020 року. Має‌ дві ‌складові ‌—  онлайн-портал Дія.Бізнес у форматі ‌one‌ ‌stop‌ ‌shop‌, на‌ ‌якому‌ ‌можна‌ ‌знайти‌ ‌всю‌ ‌необхідну‌ ‌інформацію‌ ‌для‌ ‌заснування‌ ‌та‌ ‌розвитку‌ ‌власної‌ ‌справи та офлайн мережу ‌центрів‌ ‌підтримки‌ ‌підприємців‌ ‌Дія.Бізнес, які функціонують вже ‌в‌ ‌11‌ ‌регіонах‌ ‌та‌ ‌містах‌ ‌України.‌ ‌Проєкт Дія.Бізнес реалізується Офісом з розвитку підприємництва спільно з Міністерством цифрової трансформації України.", "https://business.diia.gov.ua/"]
]

const ME = gql`
    query{
        me{
            email
        }
    }
`

const IndexPage = ({ data }) => {
    const { width } = useWindowSize();
    const isMobile = width < 768

    const { data: dataMe } = useQuery(ME)
    const [isLoginModalOn, setIsLoginModalOn] = React.useState(false)
    const me = _.get(dataMe, "me.email")
    const onCta = () => me ? navigate("/intro/") : setIsLoginModalOn(true)
    return (
        <div>
            <Seo title="Made in UA Support Project - Проєкт фінансової допомоги малим українським виробництвам" />
            {isLoginModalOn
                && <LoginModal setIsLoginModalOn={setIsLoginModalOn} />}
            <Header withMenu onCta={onCta} />
            <div style={{
                paddingTop: isMobile ? 154 : 107
            }}>
                <Hero onCta={onCta} />
                <Partners />

                <Container>
                    <div
                        id="about"
                        style={{
                            margin: isMobile ? "72px 0 0 0" : "110px 0 0 0",
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: "space-between"
                        }}
                    >
                        <div style={{ flexShrink: 0 }}>
                            <Text.H2>Про проєкт</Text.H2>
                        </div>
                        <div
                            style={{ padding: isMobile ? 0 : "0 24px 72px 10%" }}
                        >
                            <p>Маленькі крафтові виробники — супергерої війни. Ви підтримуєте українську економіку. Ви постачаєте товари першої необхідності заразом з маленькими приємностями українцям. Ви закохуєте іноземців в Україну та її культуру.</p>
                            <p>Ми хочемо, щоб українські подружжя і надалі пили ранкову каву з горнят локального виробництва. Гуцульські килими лежали поруч із камінами у французьких квартирах, а австралійські діти гралися конструкторами, виготовленими у Харкові.</p>
                            <p>Made in UA Support Project — наш спосіб підтримати бренди, які залишилися в Україні й продовжують працювати.</p>
                        </div>
                    </div>
                </Container>

                <div style={{
                    padding: "0 0 80px 0"
                }}>
                    <Container>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 16,
                            padding: "56px 0 24px 0",
                        }}>
                            <Text.H2 center>Кого шукаємо</Text.H2>
                        </div>
                        <div style={{
                            display: "flex",
                            gap: 16,
                            justifyItems: "stretch",
                            alignItems: "stretch",
                            alignContent: "stretch",
                            flexDirection: isMobile
                                ? "column"
                                : "row",
                        }}>
                            {lookingForItems.map(([title, positive, description]) => (
                                <InfoItem
                                    {...{ title, positive, description }}
                                    style={{
                                        flex: "1 1 calc(33% - 32px)",

                                    }}
                                />
                            ))}
                        </div>
                    </Container>
                </div>

                <Section title="Як отримати допомогу" id="timeline">
                    <Timeline {...{ timelineItems }} />
                    <div style={{
                        margin: "112px 0 0 0"
                    }}>
                    </div>
                </Section>

                <Section title="Як допомагаємо">
                    <Prices {...{ priceItems }} />
                    <div style={{
                        margin: "57px 0 0 0"
                    }}>
                    </div>
                </Section>



                <Section title="Ініціатори проєкту">
                    <Orgs {...{ orgItems }} />
                </Section>


                <Section title="Запитання-відповіді" id="qna">
                    <Stack>
                        {faqItems.map(([q, a]) => <Faq q={q} a={a} />)}
                    </Stack>
                    <div style={{
                        marginTop: 48
                    }}>
                    </div>
                </Section>

                <Section title="Інформаційна підтримка малого та середнього бізнесу">
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: isMobile
                            ? "column"
                            : "row"
                    }}>
                        {infoSupportItems.map(([logo, description, url]) => (
                            <InfoSupportCard {...{ logo, description, url }} />
                        ))}
                    </div>
                </Section>



                <div style={{
                    background: Colors.gray,
                }}>
                    <Container>
                        <ContanctsContainer>
                            <ContactsItem>

                                <Text.Center center>
                                    <div>
                                        Для запитів
                                    </div>
                                    <div>
                                        <Text.H1 underline>
                                            <a href="mailto: hellouamadeproject@gmail.com" style={{ color: "#000" }}>
                                                hellouamadeproject@gmail.com
                                            </a>
                                        </Text.H1>
                                    </div>
                                    <div>
                                        <a href="https://docs.google.com/document/d/e/2PACX-1vSZLnYGavglJyuCPT7wmzhU0GfYXemCnz2dsSobw-fa2q32DvUglWwIb4maRPKpqYKK-sybrv5p1Ido/pub" style={{color: "#000"}} target="_blank" rel="noreferrer" >Правила проєкту</a>
                                    </div>
                                </Text.Center>
                            </ContactsItem>


                        </ContanctsContainer>
                    </Container>
                </div>

                <div style={{
                    margin: "27px 0"
                }}>
                    <Link to="/intro/" style={{ textDecoration: "none", display: "flex", justifyContent: "center" }}><Logo /></Link>
                </div>
            </div>
        </div >
    )
}


const ContanctsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`
const ContactsItem = styled.div`

    margin: 100px 0;
    @media(max-width: 768px) {
        margin: 50px 0;
    }
`



export default IndexPage
